/* CSS Reset */
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 16px;
	zoom: 1 !important;
}

html,
body {
	height: 100%;
	width: 100%;
}

body {
	font-family: Arial, sans-serif;
	line-height: 1.5;
	background-color: #f4f4f4;
	padding: 0px;
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select {
	font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

a {
	text-decoration: none;
	color: inherit;
}

.App {
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main {
	flex: 1;
	padding: 0 12px;
}

/* Header styles */
header {
	width: 100%;
	height: 130px;
	background: linear-gradient(to right, #ffffff, #b35f77);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	gap: 20px;
}

/* .logo {
	height: 100%;
	width: 288px;
} */

.logo img {
	height: 90px;
	max-width: 245px;
}

.menu {
	display: flex;
	gap: 20px;
	justify-content: space-between;
	width: 100%;
	max-width: 800px;
}

.menu-toggle {
	display: none;
	flex-direction: column;
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.menu-toggle .bar {
	width: 25px;
	height: 3px;
	background-color: #333;
	margin: 3px 0;
}

/* Стилі для пунктів меню */
.menu-item {
	text-decoration: none;
	font-family: "Lora", serif;
	font-size: 25px;
	color: #333333;
	position: relative;
	padding: 5px 0;
	transition: color 0.3s;
}

.menu-item:hover,
.menu-item.active {
	color: #b35f77;
}

.menu-item::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -5px;
	width: 0;
	height: 2px;
	background-color: #000;
	transition: width 0.3s ease;
}

.menu-item:hover::after,
.menu-item.active::after {
	width: 100%;
}

.menu-item.active {
	color: #000;
}

/* Стилі для правого блоку */
.header-right {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

/* Соцмережі в стилі сітки */
.socials-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	position: relative;
	gap: 10px;
	width: 100px;
	height: 100px;
	position: relative;
}

.social-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
}

.social-item img {
	height: 35px;
	transition: transform 0.3s ease;
}

.social-item:hover img {
	transform: scale(1.1);
}

/* Розділювачі */
.socials-grid::before,
.socials-grid::after {
	content: "";
	position: absolute;
	background-color: #333;
}

.socials-grid::before {
	width: 100%;
	height: 2px;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.socials-grid::after {
	width: 2px;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

/* Стилі для контактної інформації */
.contact-info {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.phone {
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	color: #333;
	text-decoration: none;
	transition: color 0.3s ease;
}

.phone:hover {
	color: #b35f77;
}

.mail {
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	color: #333;
	text-decoration: none;
	transition: color 0.3s ease;
}

.mail:hover {
	color: #b35f77;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background-color: #333;
}

.nav-links {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.nav-links li {
	margin: 0 10px;
}

.nav-links a {
	color: #fff;
	text-decoration: none;
}

/* Стилі основних блоків */
.container {
	max-width: 1400px;
	margin: 0 auto;
}

.title {
	display: flex;
	height: 120px;
	justify-content: center;
	align-items: center;
	margin: 20px 0 20px 0;
	font-size: 36px;
	font-family: "Advent Pro", sans-serif;
	color: #333333;
	background-color: #bbb8b8;
	border: 1px solid #a1a1a1;
	border-radius: 15px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Профільні стилі */
.profile-container {
	display: flex;
	gap: 30px;
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.01);
	max-width: 1400px;
	margin: auto;
}

.profile-text {
	font-size: 20px;
	font-family: "Lora", serif;
	text-align: justify;
	color: #333333;
	line-height: 1.8;
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.profile-text p {
	margin-bottom: 15px;
}

.profile-image {
	flex: 1;
	display: flex;
	align-items: center;
	max-height: 100%;
}

.profile-image img {
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.about-me-wrapper .toggle-button {
	display: none;
}

.main-projects {
	margin: 20px 0;
}

.main-projects-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.main-projects-img-container {
	position: relative;
	overflow: hidden;
	border-radius: 8px;
}

.main-projects-img {
	min-width: 100%;
	min-height: 100%;
	object-fit: cover;
	transition: transform 0.3s ease;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
}

.main-projects-img-container {
	min-width: 100%;
	min-height: 100%;
	object-fit: cover;
	transition: transform 0.3s ease;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
}

.main-projects-img-container:hover .main-projects-img {
	transform: scale(1.1);
}

.projects-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); /* Адаптивні колонки */
	gap: 20px; /* Відступи між елементами */
	margin: 20px auto;
	padding: 10px; /* Внутрішні відступи */
}

.projects-img-container {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden; /* Прибираємо вихідні елементи */
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Легка тінь */
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	cursor: pointer; /* Курсор для інтерактивності */
}

.projects-img-container:hover {
	transform: scale(1.03); /* Легке збільшення при наведенні */
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Глибша тінь */
}

.projects-title {
	padding: 5px 15px;
	background-color: #fff;
	/* border-bottom: 1px solid #e0e0e0;*/
	text-align: center;
	display: flex;
	justify-content: space-between;
	gap: 15px;
}

.projects-title h2 {
	margin: 0;
	font-size: 1.2rem;
	color: #333;
	text-align: left;
}

.projects-title p {
	margin: 5px 0;
	font-size: 0.9rem;
	color: #666;
}

.projects-img {
	min-width: 100%; /* Заповнює контейнер */
	height: 100%; /* Зберігає пропорції */
	object-fit: cover; /* Розтягує зображення без викривлень */
	border-radius: 8px;
}

.project-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: bold;
	transition: opacity 0.3s ease;
}

.main-projects-img-container:hover .project-overlay {
	opacity: 0;
}

.main-projects-img-container:hover .main-projects-img {
	cursor: pointer;
}

.main-projects-img-container {
	cursor: pointer;
}

/* Form styles */
.contact-form-container {
	max-width: 600px;
	margin: 0 auto 25px;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 10px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
	text-align: center;
	font-size: 1.8rem;
	margin-bottom: 20px;
	color: #333;
}

.contact-form-container input,
.contact-form-container textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 15px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 1rem;
	transition: border-color 0.3s ease;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
	border-color: #007bff;
	outline: none;
}

.button-send {
	width: 100%;
	padding: 12px;
	background-color: #b35f77;
	color: #fff;
	border: none;
	border-radius: 5px;
	font-size: 1.1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.button-send:hover {
	background-color: #333333;
}

/* Services styles */
.services-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.services-article-container {
	width: 48%;
	margin-bottom: 4%;
	border-radius: 15px;
	overflow: hidden;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
}

.services-article-container:hover {
	transform: translate(0, 10px) scale(1.05);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.services-article-title {
	display: flex;
	flex-direction: column;
	height: 120px;
	justify-content: center;
	text-align: center;
	margin: 0;
	font-family: "Advent Pro", sans-serif;
	color: #333333;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.services-article-title h3 {
	font-size: 33px;
	margin: 0;
	line-height: 1.2;
}

.services-left-article {
	background: linear-gradient(to left, #ffffff, #c68799);
}

.services-right-article {
	background: linear-gradient(to right, #ffffff, #c68799);
}

.services-article-content {
	font-size: 18px;
	font-family: "Lora", serif;
	font-weight: 500;
	color: #333333;
	margin: 20px;
	flex-grow: 1;
}

.services-article-container ul {
	list-style: none;
	padding-left: 10px;
}

.li-check {
	background: url("./images/check-mark-svgrepo-com.svg") no-repeat left center;
	background-size: 20px 20px;
	padding-left: 30px;
}

.li-cross {
	background: url("./images/multiply-svgrepo-com.svg") no-repeat left center;
	background-size: 20px 20px;
	padding-left: 30px;
}

.li-check:hover,
.li-cross:hover {
	font-weight: 700;
	transform: scale(1.02);
	color: #b35f77;
}

.open-modal-button {
	padding: 10px 20px;
	background-color: #b35f77;
	color: #ffffff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 18px;
	transition: background-color 0.3s ease;
	margin: 10px auto;
}

.open-modal-button:hover {
	background-color: #333333;
}

/* ModalServices styles */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background: white;
	border-radius: 8px;
	position: relative;
	width: 400px;
	max-width: 90%;
}

.form-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.close-button {
	position: absolute;
	top: 0;
	right: 10px;
	background: none;
	border: none;
	font-size: 2rem;
	cursor: pointer;
}

/* ScrollToTopButton */
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
}

.scroll-button {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #b35f77;
	color: white;
	border: none;
	font-size: 24px;
	cursor: pointer;
	transition: transform 0.3s ease;

	position: fixed;
	bottom: 75px;
	right: 50px;
	z-index: 100;
}

.scroll-button:hover {
	transform: scale(1.1);
}

.menu-up {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 60px;
	right: 10px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	opacity: 0;
	transform: translateY(10px);
	transition: opacity 0.3s ease, transform 0.3s ease;
	pointer-events: none;
	width: 200px;
	z-index: 1000;
}

.menu-up-item {
	padding: 10px;
	text-align: center;
	color: #333;
	text-decoration: none;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-up-item:hover {
	background-color: #f4f4f4;
	color: #b35f77;
}

.scroll-to-top .menu-up.active {
	opacity: 1;
	transform: translateY(0);
	pointer-events: auto;
}

/* Footer styles */
.footer {
	background-color: #333333;
	color: #fff;
	padding: 10px 10%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
}

.footer-links {
	min-width: 130px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.footer-link {
	font-family: "Lora", serif;
	font-size: 18px;
	color: #f4f4f4;
	text-decoration: none;
	transition: color 0.3s ease;
}

.footer-link:hover {
	color: #b35f77;
}

.footer-slider {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 0 20px;
	position: sticky;
	width: 100%;
	max-width: 250px;
	height: 200px;
	overflow: hidden;
}

.footer-slider-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 50px;
}

.slider-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.slider-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: opacity 1s ease, transform 1s ease;
	opacity: 1;
	transform: scale(1);
}

.slider-image.hidden {
	opacity: 0;
	transform: scale(0.95);
}

.footer-contacts {
	display: flex;
	flex-direction: column;
	gap: 15px;
	font-size: 16px;
}

.footer-contact {
	font-family: "Roboto", sans-serif;
	margin: 0;
}

.contact-link {
	color: #f4f4f4;
	text-decoration: none;
	transition: color 0.3s ease;
}

.contact-link:hover {
	color: #b35f77;
}

.footer-socials {
	display: flex;
	gap: 15px;
}

.footer-icon {
	width: 30px;
	height: 30px;
	transition: transform 0.3s ease;
}

.footer-icon:hover {
	transform: scale(1.1);
}

/* Gallary */
.project-container {
	margin-top: 20px;
}

.project-description-container {
	display: flex;
	flex-direction: row;
	margin: 0 2px 20px;
	gap: 20px;
}

.project-description-container img {
	max-width: 60%;
	height: 100%;
}

.project-description {
	display: flex;
	flex-direction: column;
}
.project-description p {
	margin-top: 10px;
	text-align: justify;
}

/* Адаптивність для мобільних пристроїв */

@media screen and (max-width: 1180px) {
	.header-right {
		display: none;
	}
}
@media screen and (max-width: 768px) {
	.menu {
		display: none;
		flex-direction: column;
		width: 100%;
		background-color: #fff;
		position: absolute;
		top: 80px;
		left: 0;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	}
	.menu.active {
		display: flex;
		z-index: 100;
	}

	.menu-item {
		padding: 10px 20px;
		font-size: 18px;
		color: #333;
		text-align: center;
	}

	.menu-toggle {
		display: flex;
	}

	.logo img {
		width: 180px;
		height: 100%;
	}

	.about-me-wrapper:not(.expanded) .profile-container {
		overflow: hidden;
		max-height: 820px;
	}

	.about-me-wrapper .toggle-button {
		display: block;
		margin: 10px auto;
		background-color: #b35f77;
		color: #fff;
		border: none;
		padding: 10px 20px;
		cursor: pointer;
		border-radius: 5px;
	}

	.profile-container {
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.profile-text {
		text-align: center;
		font-size: 14px;
	}

	.profile-image {
		order: -1;
	}

	.main-projects-container {
		display: flex;
		flex-direction: column;
	}

	.projects-container {
		display: flex;
		flex-direction: column;
	}

	.projects-title {
		/* gap: 15px; */
		align-items: center;
	}

	.projects-title p:last-child {
		min-width: 45px;
	}

	.footer {
		flex-direction: column;
		align-items: center;
		gap: 15px;
	}

	.footer-slider-container {
		display: none;
	}

	.footer-links,
	.footer-contacts {
		text-align: center;
	}

	header {
		flex-direction: row;
		height: auto;
		padding: 5px 15px;
	}

	.logo {
		height: 70px;
	}

	.menu {
		flex-direction: column;
		align-items: center;
		gap: 5px;
		padding-bottom: 10px;
	}

	.title {
		height: 60px;
		font-size: 25px;
	}

	.services-article-container h3 {
		font-size: 25px;
	}

	.services-article-container {
		width: 100%;
	}

	.services-article-container:hover {
		transform: scale(1);
	}

	.services-article-title {
		height: 100px;
	}
	.services-right-article {
		background: linear-gradient(to left, #ffffff, #c68799);
	}

	.services-article-content {
		display: none;
	}

	.services-article-content.open {
		display: block;
	}

	.open-modal-button {
		display: none;
	}

	.open-modal-button.open {
		display: block;
	}
	.project-description-container {
		display: flex;
		flex-direction: column;
		/* margin: 0 2px 20px;
		gap: 20px; */
	}
	.project-description-container img {
		max-width: 100%;
		height: auto;
	}

	/* .profile-image {
		width: 100%;
		height: 100px; 
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	} */

	/* .profile-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	} */

	.back-to-top {
		bottom: 15px;
		right: 15px;
	}

	.back-button {
		padding: 8px 12px;
	}

	.scroll-button {
		width: 50px;
		height: 50px;
		bottom: 25px;
		right: 30px;
	}

	.menu-up {
		font-size: 14px;
	}
	.footer {
		flex-direction: column;
		align-items: center;
	}

	.contact-form-container {
		padding: 15px;
	}

	.footer-links,
	.footer-contacts {
		text-align: center;
	}
}

@media (max-width: 429px) {
	.profile-container {
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.profile-text {
		font-size: 14px;
		text-align: center;
	}

	.profile-title h2 {
		font-size: 28px;
	}

	/* .about-me-wrapper:not(.expanded) .profile-container {
		overflow: hidden;
		max-height: 820px;
	} */

	.services-article-container h3 {
		font-size: 22px;
	}
}
